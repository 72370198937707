//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FileSearchWrapper',
  props: {
    channelType: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
  },
  components: {
    FileSearch: () => import('@motionelements/core/src/components/ui/search-box/FileSearch.vue'),
    FileSearchSidebar: () => import('@motionelements/core/src/components/ui/search-box/FileSearchSidebar.vue'),
  },
};
